import React, { Component } from 'react'
import { Container, Close, Submit } from './styledComponents'
import styled from 'styled-components'
import Field from './field'
import ResponseWindow from './ResponseWindow'
import { H1 } from '../buttons/styledComponents'

const Title = styled(H1)`
  margin-bottom: 35px;
`

export default class Feedback extends Component {
  state = {
    name: '',
    email: '',
    phone: '',
    message: '',
    errors: [],
    responseMsg: null
  }

  setValue = (field, value) => {
    this.setState({ [field]: value })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const values = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message
    }
    
    const errors = window.validate(values)
    if (errors) {
      this.setState({ errors: errors })
    } else {
      window.sendMail(values, 
        (res) => {
          this.setState({responseMsg: res.result})
        },
        (err) => {}
      )
    }
  }

  closeResponseWindow = () => {
    this.setState({ 
      name: '',
      email: '',
      phone: '',
      message: '',
      errors: [],
      responseMsg: null
    })
  }

  render() {

    const hideText = this.props.simple !== undefined;
    const title = this.props.title;
    return (
      <>
        {
          !this.state.responseMsg ? 
          <Container className={this.props.className}>
            {
              this.props.close ?
                <Close onClick={this.props.close}/>
                : null
            }
            {!hideText && (
                <React.Fragment>
                    <H1>Обратная связь</H1>
                    <p>
                    Обращайтесь к нам по телефону, электронной
                    почте или при помощи данной формы связи
                    </p>
                </React.Fragment>
            )}
            {title && (
                <Title className="form-title">{title}</Title>
            )}
            <form onSubmit={(e) => this.handleSubmit(e)}>
              <Field
                field='name'
                label='Ваше имя *'
                placeholder='Иванов Иван Иванович'
                value={this.state.name}
                setValue={this.setValue}
                errors={this.state.errors}
                />
              <Field 
                field='email'
                label='Email *'
                placeholder='ivanovi@gmail.com'
                value={this.state.email}
                setValue={this.setValue}
                errors={this.state.errors}
                />
              <Field 
                field='phone'
                label='Телефон'
                placeholder='+7 (___) ___-__-__'
                value={this.state.phone}
                setValue={this.setValue}
                />
              <Field 
                field='message'
                label='Сообщение или вопрос'
                placeholder='Введите текст'
                value={this.state.message}
                setValue={this.setValue}
                />
              <Submit className="feedback-btn">Отправить</Submit>
            </form>
          </Container>
          : <ResponseWindow className='response-window' type={this.state.responseMsg} close={this.props.close ? this.props.close : this.closeResponseWindow}/>
        }
      </>
    )
  }
}