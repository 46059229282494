import React from 'react';
import InputMask from 'react-input-mask';

import { Field } from '../styledComponents';
import ValidationWrapper from './ValidationWrapper';

const FieldCmp = (props) => {
  const getField = () => {
    switch (props.field) {
      case 'name':
        return (
          <Field>
            <label>{props.label}</label>
            <input
              value={props.value}
              onChange={(e) =>
                props.setValue(
                  props.field,
                  e.target.value.replace(/[^/s a-zа-яё]/gi, '') // letters only
                )
              }
              type="text"
              placeholder={props.placeholder}
            />
          </Field>
        );
      case 'phone':
        return (
          <Field>
            <label>{props.label}</label>
            <InputMask
              mask="+7 (999) 999-99-99"
              maskChar={null}
              value={props.value}
              onChange={(e) => props.setValue(props.field, e.target.value)}
              type="text"
              placeholder={props.placeholder}
            />
          </Field>
        );
      case 'message':
        return (
          <Field>
            <label>{props.label}</label>
            <textarea
              value={props.value}
              onChange={(e) => props.setValue(props.field, e.target.value)}
              placeholder={props.placeholder}
            />
          </Field>
        );
      default:
        return (
          <Field>
            <label>{props.label}</label>
            <input
              value={props.value}
              onChange={(e) => props.setValue(props.field, e.target.value)}
              type="text"
              placeholder={props.placeholder}
            />
          </Field>
        );
    }
  };

  return (
    <ValidationWrapper errors={props.errors} field={props.field}>
      {getField()}
    </ValidationWrapper>
  );
};

export default FieldCmp;
