import React from 'react'
import styled from 'styled-components'

import { Container, Submit as Button, Close } from './styledComponents'

const StyledContainer = styled(Container)`
  padding: 60px 55px;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-bottom: 40px;
  }
  h1 {
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    line-height: 1.25;
    color: #2d2d2d;
    margin-bottom: 14px;
  }
  p {
    opacity: 0.75;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    letter-spacing: 0.5px;
    color: #2d2d2d;
  }
`
const StyledButton = styled(Button)`
  margin-top: 65px;
`

const ResponseWindow = ({type, close, className}) => {
  const getContentByType = () => {
    return type === 'successful' ?
      <>
        <img src='/static/images/successful-icon.png' alt='Успешно'/>
        <h1>Письмо успешно отправлено</h1>
        <p>Наш менеджер свяжется с вами в ближайшее время</p>
        <StyledButton onClick={close}>Хорошо, спасибо</StyledButton>
      </>
    : 
      <>
        <img src='/static/images/error-icon.png' alt='Ошибка'/>
        <h1>Ошибка</h1>
        <p>
          Сообщение не отправлено, повторите<br/>
          попытку через некоторое время
        </p>
      </>
  }
  return (
    <StyledContainer className={className}>
      <Close onClick={close}/>
      <Wrapper>
        {getContentByType()}
      </Wrapper>
    </StyledContainer> 
  )
}

export default ResponseWindow