import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  input {
    border-color: ${(props) => (props.invalid ? '#ff4747!important' : null)};
  }
`;
const Text = styled.p`
  position: absolute;
  right: 0;
  top: 2px;
  font-size: 13px;
  color: #ff4747;
  margin: 0;
  @media (max-width: 991px) {
    font-size: 11px;
  }
  &::before {
    content: '';
    width: 22px;
    height: 20px;
    background-image: url(/static/images/warning.png);
    position: absolute;
    left: -30px;
    top: -7px;
  }
`;

const ValidationWrapper = ({ children, errors, field }) => {
  const checkError = () => {
    return errors && errors[field];
  };

  return (
    <Wrapper invalid={checkError()}>
      {checkError() ? <Text>{errors[field]}</Text> : null}
      {children}
    </Wrapper>
  );
};

export default ValidationWrapper;
