import styled from 'styled-components'

import { MainButton } from '../buttons/styledComponents'

export const Background = styled.div`
  z-index: 11;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
`
export const Container = styled.div`
  position: relative;
  width: 540px;
  padding: 48px 85px 55px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1129px) {
    padding: 40px;
  } 
  @media (max-width: 991px) {
    padding: 15px;
    width: 320px;
    &.page-form{
      width: 100%;
      padding: 0 0 30px;
    }
  } 

  h1 {
    color: #2d2d2d;
    line-height: 0.92;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  & > p {
    opacity: 0.75;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: 0.5px;
    color: #2d2d2d;
    margin-bottom: 20px;
  }
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
export const Close = styled.span`
  cursor: pointer;
  position: absolute;
  width: ${props => props.size ? props.size : '20px' };
  height: ${props => props.size ? props.size : '20px' };
  top: 20px;
  right: 20px;
  &::after, &::before {
    position: absolute;
    left: 45%;
    content: ' ';
    height: ${props => props.size ? props.size : '20px' };
    width: 2px;
    background-color: #000;
  } 
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`
export const Submit = styled(MainButton)`
  margin-top: 35px;
`
export const Field = styled.div`
  font-size: 14px;
  line-height: 1.57;
  label {
    display: block;
    margin-bottom: 4px;
    line-height: 1.57;
    letter-spacing: 0.5px;
    color: #000;
    @media (max-width: 991px) {
      width: 100%;
      max-width: 290px;
    }     
  }
  input, textarea {
    width: 368px;
    border-radius: 2px;
    background-color: #fff;
    border: solid 1px #bcbcbc;
    padding: 10px 13px;
    @media (max-width: 991px) {
      width: 100%;
      max-width: 290px;
    }     
  }
  input {
    height: 42px;
    margin-bottom: 18px;
  }
  textarea {
    height: 140px;
    resize: none;
  }
`
